body {
  background-color: $bg-i-like;
}

h1 {
  font-weight: 300;
}

.particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: $bg-i-like;
  height: $header-height;

  &.transparent {
    background-color: transparent;
  }

  .navbar-brand img {
    display: block;
  }

  .nav-link.donate {
    color: $donate;
    font-weight: bold;

    &:hover {
      color: lighten($donate, 15%);
    }
  }
}

.site-content {
  background-color: #fff;
  padding-top: $header-height;
}

#hero-bg-animated {
  background-color: $bg-i-like;
  height: 70vh;
  min-height: 400px;
  max-height: 720px;
  padding-top: $header-height;
  margin-top: -$header-height;
}

.top-hero {
  position: relative;
  z-index: 1;
}

.top-hero h3 {
  opacity: 0.7;
  font-weight: 300;
  font-size: 1.3rem;
}

.splash-dark {
  background-color: #eee;
}

.home {
  article {
    h2 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }
}

article {
  padding: 30px 0;

  h2 {
    margin-bottom: 1.5rem;
    color: #444;
    // color: #465E69;
  }
}

.footer {
  border-top: 4px solid $blue-dim;
  padding: 3em 0;
  text-align: center;
  color: $gray-600;

  section + section {
    margin-top: 1.5em;
  }

  section p:last-child {
    margin-bottom: 0;
  }

  .twitter-icon {
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.2;
    }
  }

  .branding {
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.2;
    }
  }

  .nav-item {
    &:not(:first-child) {
      border-left: 1px solid;
    }
  }

  .nav-link {
    color: rgba(#fff, 0.5);
    padding: 0 1rem;

    &:hover {
      color: rgba(#fff, 0.9);
    }
  }
}

// .brackets {
//     &:before {
//         content: '{';
//     }

//     &:after {
//         //content: '}';
//     }

//     &:before,
//     &:after {
//         position: absolute;
//         left: 0;
//         font-size: 1.5rem;
//     }
// }

.page-404,
.page-500 {
  text-align: center;
  padding: 4rem 0;

  h2 {
    font-size: 5rem;
    font-weight: 300;
  }

  img {
    height: 20rem;
    max-width: 100%;
  }

  p {
    font-size: 1.25rem;
    margin: 0;

    + p {
      margin-top: 1.5rem;
    }
  }
}

/* PAGES */

.page {
  margin-top: -30px;
  // border-top: 1px solid #597fde;

  .top-hero {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 30%;
  }

  @keyframes movingTopToBottom {
    0% {
      transform: translateY(0);
    }
    100% {
      /*top: 247px;*/
      transform: translateY(2470px);
    }
  }

  @keyframes movingBottomToTop {
    0% {
      top: 165px;
    }
    100% {
      top: 0;
    }
  }

  .hero-container {
    height: 300px;
    background-color: $bg-i-like;
    position: relative;
  }
  .hero {
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    // background-color: #2E3033;
    overflow: hidden;
    position: absolute;

    .highlight {
      opacity: 0.5;
      font-size: 30px;
      line-height: 1.2;
      // animation: movingTopToBottom 8s linear infinite;
      animation: movingTopToBottom 250s linear infinite;
      position: absolute;
      display: inline-block;
      color: transparent;
      // text-shadow: 0 0 3px #e6db74;
      top: -2700px;

      pre {
        font-size: 24px;
        border-left: 0 none;
      }

      .mi,
      .kc {
        color: transparent;
        text-shadow: 0 0 5px #bd93f9;
      }

      .s2 {
        color: transparent;
        text-shadow: 0 0 5px #93b4ff;
      }

      .p,
      .nl {
        color: transparent;
        text-shadow: 0 0 5px #dce7fd;
      }
    }
  }

  .page-content {
    h2,
    h3,
    h4 {
      margin: 1rem 0 2.5rem 0;
    }

    img {
      max-width: 50%;
    }
  }
}

.ua-heart-icon {
  margin: 0 0.2em;
  position: relative;
  top: 3px;
  display: inline-block;
  width: 20px;
  height: 18px;
  vertical-align: text-top;
  background-size: cover;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAuCAMAAABd9bpjAAABGlBMVEUAAAD/1QD/0wD/1gAAW7sAW7v/1QAAWrsASrUAeLQAXbvp0UYAW7v/1QD/1QAAW7v/1QD/1AD/1gD/0gD/1gD/1QAAW7sAWrsAW7v/1gD/1QAAW7sDW7sAWrv/1QD/1gAAW7sAW7sAW7sAW7wAXLv/1QAAXb32yij/2QAaXboAUr9dcbL60iv+1BX/1QAAW7wEW7z40C4AW7sAW7wAW7wAVLz/2AAAWMD/1QAAXLz90xf91Bj/1wAAW7z/2QAAXLwAXLwAW7sAWr4AWLn/0gAAW7v/1gBdcbEAU74YXbr60Sv91BX/4QAAYcgAXsH/3gAAXb8AY8wAX8T/3AAAYMUAYMb/4wASXr7/5C//4hX+1BX/6QAAW80AWcpdpv3+AAAARXRSTlMA/gb4/Pr6+QMEBwTt5+LfKSQeGvHr5J6JON3KlF3X0s7CdWRNMBYRDfz5+fj49POkfnxRRiYWEO3n3c7GtrWyrzgzHRPj4WhPAAAB60lEQVRIx5XRCW/aMBjG8ae1TVJIS09gHCsURtdrvdfdt9Md2IRrvbbv/zWaOFQWRFHsv6JEeaWfbCeIWwKuGuWax2mpVe/kw4G68PXsS7NYbH6sXLjRQOe66Fa8sRgGnMvwUdzJYyl0jWYgJkMphxMh1cyFXqJ9LALKGOU8ustB8QL4VhND9UqZmpU6gDLqUR4EDtdRZzjeaQTCoTOzSQVwp+LzwGF8JodLGThzMzoox8YN13D4fJRylpg5g0p0ijzagnGzKBXnkel6knLDmCzlAVTUtgxzxBlwVZLMnFBZzONcMG6T7KAuHBvBRB2tMbMikxbeBtSGUFkCt42CWwfPcmOBh9rYirBxDWXbj3yCht2vpJM2ut7N3i/j9m6OvwP1f/s/jdv/fwLg8uD64Nq8S6CA09vD34Yd3p6GoAB8eiC+UeThA8Ii9f7u6P5PZvdHd+92UYjNy+2/ZCErn/RWNrAI1aKJ8XNaxOZ5lnlaQ5tdbVLE8vqT0KaXbnzS10KbH1VtkudICGVeV3u5lF31t9amImmImdBmYyU0yV313yiRbbTYXFUi1cyfR4s0s748uw4ZaWFk/Nzo1QslDI0WWWZtq5+bnmNElDAyxI/FMyVMja+FiVnd7BMyyilhbnq9BSUszHY1TTwCT2xCUQPeuGoAAAAASUVORK5CYII=);
}
