$font-family-sans-serif: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$headings-font-weight: 600;

$blue: #0090ff;
$blue-dark: #0073cc;
$navbar-dark-color: #fff;
// $body-color: #2B3A42;
$blue-dim: #597fde;
$bg-i-like: #182834;
$donate: #ffcc00;

// $dark-bg-color: #242842;
// $dark-bg-color: #35383b;
$dark-bg-color: #2e3033;

$header-height: 56px;
